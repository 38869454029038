/*
 * url-absolutify.js
 * Processes a whitelist of meta tags and converts each matching element's content into
 * an absolute URL (Open Graph doesn't support relative URLs).
 */

const whitelist = ['og:url', 'og:image'];

document.addEventListener('DOMContentLoaded', () => {
  const tags = document.querySelectorAll('meta[property^="og:"]');

  tags.forEach((tag) => {
    if (whitelist.includes(tag.attributes.property.value)) {
      let { value } = tag.attributes.content;
      if (value.startsWith('/')) {
        value = window.location.origin + value;
      }
      tag.attributes.content.value = value;
    }
  });
});
