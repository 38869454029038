/*
 * block-link.js
 *
 * Processes elements with the '.block-link' class and makes them into clickable blocks. The element should contain a
 * child <a> tag with class '.block-link-main' that is used to derive the block's target URL when clicked.
 */

document.addEventListener('DOMContentLoaded', () => {
  const blocks = document.querySelectorAll('.block-link');

  blocks.forEach((block) => {
    block.classList.add('clickable');
    block.addEventListener('click', (event) => {
      const isTextSelected = window.getSelection().toString();
      const link = event.currentTarget.querySelector('a.block-link-main');
      if (link && !isTextSelected) {
        link.click();
      }
    });
  });
});
