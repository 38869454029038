/*
 * flex-gap-support.js
 * Detects support for flex gap (currently not supported in Safari). If not supported, the
 * 'no-flexbox-gap' class is added to the document body.
 */

function isFlexGapSupported() {
  // Create a flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';
  flex.style.position = 'absolute';

  // Create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // Append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);

  // Flex container should be 1px high due to the row-gap
  const isSupported = flex.scrollHeight === 1;

  // Remove element from the DOM after you are done with it
  flex.parentNode.removeChild(flex);

  return isSupported;
}

document.addEventListener('DOMContentLoaded', (_event) => {
  if (!isFlexGapSupported()) {
    document.body.classList.add('no-flexbox-gap');
  }
});
